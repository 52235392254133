/* eslint-disable react/prop-types */
import React from "react";
import FlyCartItemsContainer from "./FlyCartItemsContainer";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";

function DrawerCart(props) {
  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={() => props.handleClose(false)}
    >
      <div className="drawer-width lg:max-w-screen-lg w-full lg:w-126 min-h-screen">
        <div className="w-full flex flex-row pl-5 align-middle h-16 items-center">
          <div className="w-full justify-left flex font-helvetica-text text-2xl font-medium antialiased">
            {/*<CartIcon insideCart={true}/>*/}
            Carrito
          </div>
          <div className="cursor-pointer flex items-center mr-5 h-10">
            <CloseIcon
              className="hover:text-gray-label text-black"
              onClick={() => props.handleClose(false)}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "gray",
                },
              }}
            />
          </div>
        </div>

        <div className="overflow-hidden w-full">
          {/*AQUI VA EL COMPONENTE DE CARRITO*/}
          <FlyCartItemsContainer />
        </div>
      </div>
    </Drawer>
  );
}

const DrawerCartM = React.memo(DrawerCart);
export default DrawerCartM;
