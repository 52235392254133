import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Switcher from "../icons/switcher";

function fill() {
    const el = 5;
    let arr = [];
    for (let i = 0; i < el; i++) {
        arr.push(false);
    }
    return arr;
}

const styles = {
    titleMenu: {
        width: "100%",
        maxWidth: "480px",
        backgroundColor: "#FFFFFF",
        textAlign: "start",
        fontSize: "1.125rem",
        lineHeight: "19px",
        fontFamily: "Helvetica Now Text",
        fontWeight: 500,
        color: "#000000",
        padding: "0px",
        cursor: "pointer",
    },
    menuBody: {
        color: "#000000",
        fontSize: "1rem",
        fontFamily: "Helvetica Now Text",
        fontStyle: "normal",
        lineHeight: "20px",
        marginBottom: "8px",
    },
};

export function CustomizedAccordionsCookies(props) {
    const [open, setOpen] = useState(fill());
    const [expanded, setExpanded] = useState("");

    const handleClick = (index) => {
        open[index] = !open[index];
        setOpen([...open]);
    };

    const [state, setState] = React.useState({
        panel0: false,
        panel1: false,
        panel2: false,
        panel3: false,
        panel4: false,
        panel5: false,
        panel6: false,
    });

    const handleChange2 = (event, panel, action) => {
        event.stopPropagation();
        setState({ ...state, [panel]: !action });
    };

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const { sections } = props;

    return (
        <div className="w-full">
            <div className="items-start justify-items-start justify-start text-left">
                <ul className="font-helvetica-text font-normal py-2">
                    {sections.map((section, index) => {
                        return (
                            <>
                                <hr style={{ margin: "18.5px 0" }} />
                                <li onClick={() => handleClick(index)} className="flex justify-between items-center" style={{ paddingLeft: "0px", paddingRight: "0px", cursor: "pointer" }}>
                                    <h3 className="w-full ml-1 lg:ml-5 font-medium leading-8 font-helvetica-text text-base cursor-pointer hover:text-blue-prodbuttons text-gray-text">
                                        {section.title}
                                    </h3>
                                    {index == 0 ? (
                                        <p className="font-helvetica-text text-sm"> Siempre activado</p>
                                    ) : (
                                        <div onClick={(e) => handleChange2(e, `panel${index}`, state[`panel${index}`])}>
                                            <Switcher isChecked={state[`panel${index}`]}/>
                                        </div>
                                    )}
                                    {open[index] ? (
                                        <ExpandMore style={{ fontSize: 25 }} />
                                    ) : (
                                        <ChevronRightIcon style={{ fontSize: 25 }} />
                                    )}
                                </li>
                                <Collapse in={open[index]} timeout="auto" unmountOnExit>
                                    <p className="ml-1 lg:ml-5 text-sm font-helvetica-text">
                                        {section.content}
                                    </p>
                                </Collapse>
                            </>
                        );
                    })}
                    <hr style={{ margin: "18.5px 0" }} />
                </ul>
            </div >
        </div >
    );
}