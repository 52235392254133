/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { CustomizedAccordionsCookies as Accordion } from "./cookie-accordion";

// Estilos personalizados usando `styled` para el diálogo de Cookies
const FullWidthDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    alignItems: "flex-end", // Alinea el diálogo al fondo
    justifyContent: "center", // Centra horizontalmente
  },
  "& .MuiDialog-paper": {
    borderRadius: 0,
    width: "100%",  // Ancho completo
    maxWidth: "none", // Desactiva el ancho máximo predeterminado
    margin: 0,
    backgroundColor: "#F2F2F2",
    color: "#737373",
    left: 0,
    bottom: 0,
    position: "relative",
  },
}));

// Estilos personalizados para el diálogo de Settings
const CenteredDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 5,
    position: "relative",
    backgroundColor: "#FFFFFF",
    color: "#737373",
    width: "50%", // Ajuste de ancho aproximado al 50%
    maxWidth: "sm",
    margin: "0 auto",
  },
}));

const Settings = (props) => {
  return (
    <CenteredDialog
      open={props.open}
      maxWidth={"sm"} // Se mantiene maxWidth para un tamaño controlado
    >
      <DialogTitle>
        <span className="font-helvetica-text font-medium px-4">Resumen de privacidad</span>
        <IconButton
          style={{ position: "absolute", right: 0, top:7 }}
          aria-label="close"
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="custom-scrollbar">
        <p className="font-helvetica-text font-normal text-sm text-justify pb-3 px-4">
          Este sitio web utiliza cookies para mejorar su experiencia mientras
          navega por el sitio web. De estas, las cookies que se clasifican como
          necesarias se almacenan en su navegador, ya que son esenciales para el
          funcionamiento de las funcionalidades básicas del sitio web. También
          utilizamos cookies de terceros que nos ayudan a analizar y comprender
          cómo utiliza este sitio web. Estas cookies se almacenarán en su
          navegador solo con su consentimiento. También tiene la opción de optar
          por no recibir estas cookies. Pero la exclusión voluntaria de algunas
          de estas cookies puede afectar su experiencia de navegación.
        </p>

        <Accordion
          sections={[
            {
              title: "Necesario",
              content:
                "Las cookies necesarias son absolutamente esenciales para que el sitio web funcione correctamente. Estas cookies garantizan funcionalidades básicas y características de seguridad del sitio web, de forma anónima.",
            },
            {
              title: "Funcional",
              content:
                "Las cookies funcionales ayudan a realizar ciertas funcionalidades, como compartir el contenido del sitio web en plataformas de redes sociales, recopilar comentarios y otras características de terceros.",
            },
            {
              title: "Rendimiento",
              content:
                "Las cookies de rendimiento se utilizan para comprender y analizar los índices de rendimiento clave del sitio web, lo que ayuda a brindar una mejor experiencia de usuario a los visitantes.",
            },
            {
              title: "Analítica",
              content:
                "Las cookies analíticas se utilizan para comprender cómo los visitantes interactúan con el sitio web. Estas cookies ayudan a proporcionar información sobre métricas, el número de visitantes, la tasa de rebote, la fuente de tráfico, etc.",
            },
            {
              title: "Anuncio publicitario",
              content:
                "Las cookies publicitarias se utilizan para proporcionar a los visitantes anuncios y campañas de marketing relevantes. Estas cookies rastrean a los visitantes en los sitios web y recopilan información para proporcionar anuncios personalizados.",
            },
            {
              title: "Otras",
              content:
                "Otras cookies no categorizadas son las que se están analizando y aún no se han clasificado en una categoría.",
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <div className="justify-center w-full flex lg:mx-10">
          <button
            onClick={() => {
              props.setParentOpen(false);
              props.setOpen(false);
              localStorage.setItem("cookies", "{}");
            }}
            className="focus:outline-none bg-black-semi hover:bg-black-semi/80 text-white text-sm rounded-md p-2 w-50"
          >
            Guardar y Aceptar
          </button>
        </div>
      </DialogActions>
    </CenteredDialog>
  );
};

const Cookies = (props) => {
  const [openSettings, setOpenSettings] = useState(false);

  return (
    <>
      <Settings open={openSettings} setOpen={setOpenSettings} setParentOpen={props.setOpen} />
      <FullWidthDialog
        open={props.open}
        fullWidth
        maxWidth={false} // No establece un ancho máximo para usar el 100%
      >
        <div className="flex flex-col md:flex-row py-2 px-2 xl:px-0 container mx-auto">
          <div className="font-helvetica-text font-normal text-sm text-left p-2">
            {props.anuncio} <a href="/politica-de-privacidad/" className="underline text-blue">Políticas de Privacidad</a>.
          </div>
          <div className="flex flex-row justify-center items-center align-middle my-2 md:my-0">
            <button className="focus:outline-none text-black hover:text-black/80 text-sm font-medium p-2 mx-4 w-30" onClick={() => {
              setOpenSettings(true);
            }}>
              Configuración
            </button>
            <button onClick={() => {
              props.setOpen(false);
              localStorage.setItem("cookies", "{}");
            }} className="focus:outline-none bg-black-semi hover:bg-black-semi/80 text-white text-sm rounded-md p-2 mr-2 w-30">
              Aceptar
            </button>
          </div>
        </div>
      </FullWidthDialog>
    </>
  );
};

export default Cookies;
