/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from "react";
import { v4 } from "uuid";
import Image from "next/image";
import Link from "next/link";
import { isMobile } from "react-device-detect";
import { isEmpty } from "lodash";
import { getUpdatedItems } from "../../../functions";
import clientConfig from "../../../../client-config";
import TrashIcon from "../../icons/TrashIcon";
import TruncateText from "../../utils/TruncateText";
import {getImageFromCloudinary} from "../../../functions";
import MyImageComponent from "../../addons/MyImageComponent";

const FlyCartItem = ({
	item,
	products,
	updateCartProcessing,
	handleRemoveProductClick,
	updateCart,
}) => {

	const [productCount, setProductCount] = useState(item.qty);
	const [alertStock, setAlertStock] = useState("");
	const [price, setPrice] = useState(item?.totalPrice);
	/*
	 * When user changes the qty from product input update the cart in localStorage
	 * Also update the cart in global context
	 *
	 * @param {Object} event event
	 *
	 * @return {void}
	 */

	const handleQtyChange = (event, cartKey, op) => {

		//if ( typeof window !== "undefined" ) {

		event.stopPropagation();
	//	setProductCount(newQty);
		// If the previous update cart mutation request is still processing, then return.
		if (updateCartProcessing) {
			return;
		}

		// If the user tries to delete the count of product, set that to 1 by default ( This will not allow him to reduce it less than zero )
		const newQty = (op) ? parseInt(op) : 0;

		if (products.length) {
			const updatedItems = getUpdatedItems(products, newQty, cartKey);
			let noMore = false;

			updatedItems.map(function (item) {
				if (item.key == cartKey) {
					if (item.quantity < newQty) {
						setProductCount(item.quantity);
						setAlertStock("Último(s) Item(s)");
						noMore = true;
					}
					else {
						// Set the new qty in state.
						setProductCount(newQty);
						setAlertStock("");
					}
				}
			});

			if (!noMore) {
				let tmpCart = JSON.parse(localStorage.getItem("classic-cart"));
				let tmpPrice = 0;
				if (tmpCart) {
					for (let i = 0; i < tmpCart.products.length; i++) {
						if (tmpCart.products[i].cartKey == cartKey) {
							tmpPrice = tmpCart.products[i].qty * tmpCart.products[i].price;
							tmpPrice = tmpPrice.toString();
							tmpPrice = tmpPrice.replace(".", ",");
							tmpPrice = tmpPrice.replace(/\B(?=(\d{3})(?!\d))/g, ".");
							setPrice(tmpPrice);
							tmpCart.products[i].totalPrice = tmpPrice;
							localStorage.setItem("classic-cart", JSON.stringify(tmpCart));
						}
					}
				}

				updateCart({
					variables: {
						input: {
							clientMutationId: v4(),
							items: updatedItems
						}
					},
				});
			}

		}

		//	}
	};

	let image;
	if (!isEmpty(item?.image?.guid)) {
		image = item?.image?.guid;
	}
	else {
		image = clientConfig.flyCartImagePlaceholder;
	}

	var stripedHtml = item.shortName.replace(/<[^>]+>/g, "");//eliminar tags html
	//var shortName = stripedHtml.charAt(0).toUpperCase() + stripedHtml.slice(1).toLowerCase(); //primera mayusc y demas minusculas
	var shortName = stripedHtml.toLowerCase();
	//var decodedStripedHtml = he.decode(stripedHtml);

	return (
        <div className="classic-cart-fly-item flex justify-between mt-4">
			<Link
                href="/[slug]"
                as={`/${item?.slug}`}
                prefetch={false}
                className="flex-none w-24.5 md:w-27.5">

                {image ? (
                    <MyImageComponent
                        src={isMobile ? getImageFromCloudinary(image, ":best", 98, 98) : getImageFromCloudinary(image, ":best", 110, 110)}
                        alt={item?.name}
                        className="rounded-lg"
                        width={isMobile ? 98 : 110}
                        height={isMobile ? 98 : 110}
                        quality={90}						
                    />
                ) : !isEmpty(clientConfig.flyCartImagePlaceholder) ? (
                    <Image
                        src={clientConfig.flyCartImagePlaceholder}
                        alt={item?.name}
                        className="rounded-lg"
                        width={isMobile ? 98 : 110}
                        height={isMobile ? 98 : 110}
                        placeholder="blur"
                        blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCACJAIkDASIAAhEBAxEB/8QAGgAAAwEBAQEAAAAAAAAAAAAAAAIDBAEGBf/EABoQAQEBAQEBAQAAAAAAAAAAAAABAgMRMSH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A9mAAAAAAAAAAAAAAAAAAQ6MnRq6svQGfRTaKD7gAAAAAA5QDpfTAAAAAABy/HXL8BDoydGnoy9AQ0V3VL6D7wAAAAAW0ydoD08T9NmgcAAAAALr4Ym7+Az9KydK09aydKCOqX0apfQeiAAAAA5r4lafdStAenzUvT5oLR0macAAACXSqX4j0oM/WsnStPWsnSgjql9GqT0HpwAAAc1fwEt1LVPuo2gb02al6bNBozVJUM1XNA4cFoOarP0quqh0oM/WsnStPWsfSglqk9GqX0HrAAAJunR3QS3UrT7qNoO+nzUvTZoNGapmoZquaCsotLK5aDmqz9KrqodKDP1rJ0rR1rJ1oI6v6T0apPQezAAOa+Ibqu6hugjupWn3UtUB6fNS9PmgvmqZqOapmgrKLSyi0C6rP0q2qz9KDP1rH1rV1rH1oIbpPRuk9B7kAUE91n3V9s+wR3UdVTaWgc9PmpnyC2armo5UyCkotcgoE1WfpV9M/QGbrWPrWrqx9QZ90nruyeg965fjrl+Altn20bZ9ghtHSu0qDhskPkFcq5SyrkDxyiCgnpn6NGmfoDL1Yurb1YuoMuyH2QH//2Q=="
                        quality={90}
                    />
                ) : null}

            </Link>
			<div className="flex flex-col h-24.5 md:h-27.5 ml-4 text-black w-[176px] md:w-[286px]">
				<div className="font-helvetica-text font-medium antialiased">
					<Link
                        href="/[slug]"
                        as={`/${item?.slug}`}
                        prefetch={false}
                        className="capitalize text-[16px] truncate text-ellipsis whitespace-nowrap">

                        {!isMobile ? <TruncateText text={shortName} maxLength={30} /> 
						: <TruncateText text={shortName} maxLength={15} />}

                    </Link>
				</div>
				<span className="font-helvetica-text font-normal antialiased relative -mt-[1px]">{item.color}</span>
				<span className="font-helvetica-text font-normal antialiased relative -mt-0.5">{item.talla}</span>
				<div className="font-helvetica-text font-medium text-base antialiased mt-2">
					{("string" !== typeof price) ? "$" + price.toFixed(3) : price}
				</div>
			</div>
			<div className="flex flex-col justify-between h-24.5 md:h-27.5 py-1 items-right">
				{/* Remove item */}
				<span
					className="relative cursor-pointer"
					onClick={(event) => handleRemoveProductClick(event, item.cartKey, products)}>
					<TrashIcon className="absolute right-0 hover:text-gray-label text-black" />
				</span>
				{alertStock ? <p className="text-red font-helvetica-text font-normal absolute top-31 ml-3">{alertStock}</p> : ""}
				{/* Qty Input */}
				<div className="classic-cart-qty">
					{/* @TODO Need to update this with graphQL query */}
					<div
						className="flex flex-row border h-7.5 w-16 rounded-lg border-gray-light2 relative"
					>
						<button
							className="flex justify-center items-center hover:text-gray-label text-black h-full w-20 flex rounded-l focus:outline-none cursor-pointer"
							onClick={(event) => { handleQtyChange(event, item.cartKey, productCount - 1); }}
						>
							<span className="text-[16px] font-helvetica-text font-normal antialiased">-</span>
						</button>

						<div
							className="w-24 text-xs md:text-base flex items-center justify-center cursor-default"
						>
							<input
								type="text"
								min="0"
								readOnly
								data-cart-key={"fly-" + item.cartKey}
								value={productCount >= 1 ? productCount : 0}
								className={`form-control ${updateCartProcessing ? "cursor-not-allowed" : ""} outline-none focus:outline-none border-0 w-4 text-sm font-helvetica-text font-normal antialiased`}
								onChange={(event) => handleQtyChange(event, item.cartKey)}
							/>
						</div>

						<button
							className="flex justify-center items-center hover:text-gray-label text-black h-full w-20 flex rounded-r focus:outline-none cursor-pointer"
							onClick={(event) => { handleQtyChange(event, item.cartKey, productCount + 1); }}
						>
							<span className="text-[16px] font-helvetica-text font-normal antialiased">+</span>
						</button>
					</div>
				</div>
			</div>
		</div>
    );
};

export default FlyCartItem;
