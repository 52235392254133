/* eslint-disable react/prop-types */
import React from "react";

const Switcher = ({isChecked}) => {

    return (
      <>
        <div className='flex cursor-pointer select-none items-center'>
          <div className='relative'>           
            <div
              className={`box block h-[26px] w-[42px] rounded-full ${
                isChecked ? "bg-green-whatsapp" : "bg-gray-contorno"
              }`}
            ></div>
            <div
              className={`absolute left-0.5 top-0.5 flex h-[22px] w-[22px] items-center justify-center rounded-full bg-white transition ${
                isChecked ? "translate-x-[16px]" : ""
              }`}
            ></div>
          </div>
        </div>
      </>
    );
};

export default Switcher;